import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocalization } from 'gatsby-theme-i18n'

const Seo = ({ content }) => {
  const { locale } = useLocalization()
  const { title, seo } = content || {}
  const companyName = {
    en: 'Zhuoyu',
    zh: '拙鱼',
  }

  const defaultTitle = `${companyName[locale]} | ${title}`
  const setTitle = seo?.title
    ? `${companyName[locale]} | ${seo.title}`
    : defaultTitle
  const setDescription = seo?.description || null
  // const setKeywords = seo?.keywords || null

  return (
    <Helmet>
      {setTitle && <title>{setTitle}</title>}
      {setDescription && <meta name='description' content={setDescription} />}
      {/* {setKeywords && <meta name='keywords' content={setKeywords} />} */}
    </Helmet>
  )
}

export default Seo
